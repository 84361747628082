.changeactivecatalog {
  padding: 5px;
  background-color: rgb(241, 238, 234);
  border-radius: 15px;
  font-size: 16px;
  display: inline-block;
}
.containerarticel {
  padding-top: 320px;
}
.quilon,
.quilon2 {
  display: block;
  max-width: 320px;
  margin: 20px auto;
}
.atricelandcatalog {
  max-width: 1140px;
  display: flex;
  justify-content: center;
  flex-flow: row-reverse;
  margin: 10px auto 20px;
}
.articelquilon {
  max-width: 800px;
  margin: 20px auto;
}
.articelquilon h2 {
  text-align: center;
  padding: 20px 0;
}
.articelquilon p,
.articelquilon ul,
.articelquilon ol {
  font-size: 18px;
  text-indent: 30px;
  text-align: justify;
  padding: 15px;
}
.articelquilon p {
  max-width: 98%;
  padding: 10px;
}
.articelquilon ul,
.articelquilon ol {
  margin-left: 20px;
}
.catalogs {
  color: #3e77aa;
  border-right: 1px solid rgba(62, 119, 170, 0.3);
  margin: 20px auto;
}
.catalogs ul {
  margin-left: 20px;
  list-style-type: circle;
}
.catalogs li {
  font-weight: 500;
  padding: 10px 0 20px 0px;
  width: 230px;
}
.catalogs h3 {
  padding: 20px 0;
  padding-left: 15px;
}
.catalogs a {
  color: #3e77aa;
}
.catalogs a:hover {
  color: rgb(230, 100, 100);
  border-bottom: 1px solid rgb(230, 100, 100);
}
@media (max-width: 700px) {
  .atricelandcatalog {
    display: block;
  }
  .catalogs li {
    font-weight: 500;
    padding: 10px 0 20px 0px;
    width: 100%;
  }
  .catalogs {
    max-width: 300px;
  }
}
@media (max-width: 600px) {
  .containerarticel {
    padding-top: 200px;
  }
}
@media (max-width: 350px) {
  .catalogs li {
    font-weight: 500;
    padding: 10px 0 20px 0px;
  }
  .catalogs {
    max-width: 250px;
  }
}
