.vectorup {
  transform: rotate(180deg);
  transition: 0.3s;
}
.none {
  transition: 0.3s;
}
.select {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  padding: 7px;
  width: 50px;
  min-height: 35px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  font-size: 15px;
}
.ee {
  position: absolute;
  top: 38px;
  left: 0px;
  width: 50px;
  height: 40px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  font-size: 15px;
  z-index: 5;
  border-top: 1px solid#fff;
}
.ee a {
  display: inline-block;
  color: #fff;
  width: 100%;
  height: 100%;
  padding-top: 10px;
}
@media (max-width: 330px) {
  .select {
    padding: 3px;
    width: 35px;
    min-height: 30px;
    font-size: 13px;
  }
  .ee {
    position: absolute;
    top: 35px;
    left: 0px;
    width: 35px;
    height: 30px;
    font-size: 13px;
  }
  .ee a {
    padding-top: 5px;
  }
}
