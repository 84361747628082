.connection {
  width: 80px;
  height: 80px;
  background-color: black;
  text-align: center;
  position: fixed;
  bottom: 25px;
  right: 2%;
  animation: blink-animation 1.6s infinite;
  z-index: 100;
  border-radius: 50%;
  cursor: pointer;
  color: white;
  transition: 0.5s;
}
.connection span {
  display: block;
  text-transform: uppercase;
  margin: 30px auto 0;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-weight: 600;
}
.connection:hover {
  opacity: 1;
  animation: blink-animation none;
}
.connection:focus {
  background-color: bisque;
}
.connection:active {
  background-color: brown;
}
.connection-whatsapp,
.connection-viber,
.connection-telegram,
.connection-phone {
  scale: 0.8;
}
.connection-whatsapp-novisible,
.connection-viber-novisible,
.connection-telegram-novisible,
.connection-phone-novisible {
  scale: 0.8;
}
.connection-whatsapp-novisible {
  position: fixed;
  bottom: 10px;
  right: 35px;
  z-index: 90;
  cursor: pointer;
  opacity: 0;
  transition: 0.5s;
}
.connection-whatsapp {
  position: fixed;
  bottom: 100px;
  right: 35px;
  z-index: 90;
  cursor: pointer;
  opacity: 1;
  transition: 0.5s;
}
.connection-viber-novisible {
  position: fixed;
  bottom: 10px;
  right: 35px;
  z-index: 90;
  cursor: pointer;
  opacity: 0;
  transition: 0.6s;
}
.connection-viber {
  position: fixed;
  bottom: 155px;
  right: 35px;
  z-index: 90;
  cursor: pointer;
  opacity: 1;
  transition: 0.6s;
}
.connection-telegram-novisible {
  position: fixed;
  bottom: 10px;
  right: 35px;
  z-index: 90;
  cursor: pointer;
  opacity: 0;
  transition: 0.7s;
}
.connection-telegram {
  position: fixed;
  bottom: 210px;
  right: 35px;
  z-index: 90;
  cursor: pointer;
  opacity: 1;
  transition: 0.7s;
}
.connection-phone-novisible {
  position: fixed;
  bottom: 10px;
  right: 35px;
  z-index: 90;
  cursor: pointer;
  opacity: 0;
  transition: 0.8s;
}
.connection-phone {
  position: fixed;
  bottom: 265px;
  right: 35px;
  z-index: 90;
  cursor: pointer;
  opacity: 1;
  transition: 0.8s;
}
.cross-novisible {
  position: fixed;
  bottom: 10px;
  right: 35px;
  z-index: 90;
  cursor: pointer;
  opacity: 0;
  transition: 0.9s;
}
.cross {
  position: fixed;
  bottom: 320px;
  right: 20px;
  z-index: 90;
  cursor: pointer;
  opacity: 1;
  transition: 0.9s;
}
.cross img {
  background-color: white;
  border-radius: 50%;
}
.cross img:active {
  background-color: red;
  border-radius: 50%;
}
@keyframes blink-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
