html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  max-width: 100%;
}

body {
  /* serif */
  font-family: Georgia, serif;
  font-family: 'Lucida Bright', serif;
  font-family: Garamond, serif;
  font-family: Cambria, serif;
  font-family: 'Book Antiqua', serif;
  /* sans-serif */
  font-family: Lato, Roboto, Arial, sans-serif;
  font-family: Candara, sans-serif;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-family: Tahoma, Geneva, Verdana, sans-serif;
  font-family: 'Century Gothic', sans-serif;
  font-family: Calibri, sans-serif;
}
