.main-first {
  padding-top: 320px;
  padding-bottom: 15px;
  background-image: url(../../../img/fon4.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  background-attachment: fixed;
}
.main-important {
  padding-top: 20px;
  padding-bottom: 15px;
  background-image: url(../../../img/fon4.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  background-attachment: fixed;
}
.main-third {
  padding-bottom: 15px;
  background-image: url(../../../img/fon4.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  background-attachment: fixed;
}
@media (max-width: 600px) {
  .main-first {
    padding-top: 200px;
  }
}
