.drop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0);
  backdrop-filter: blur(2px);
}
.menu__list {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 22px;
}
.menu__item {
  position: relative;
  text-transform: uppercase;
  font-weight: 600;
  max-width: 100%;
}
.menu__item a {
  padding: 10px 25px;
  border-radius: 5px;
}
.active {
  animation-name: navlink;
  animation-duration: 0.4s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  animation-direction: alternate;
  animation-fill-mode: forwards;
}
/* Menu + Zayavka */
.menuzayavka {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  gap: 20px;
}
@keyframes navlink {
  from {
    background-color: azure;
    color: rgb(79, 77, 75);
    opacity: 0.2;
  }
  to {
    background-color: azure;
    color: rgb(79, 77, 75);
    opacity: 1;
  }
}

@media (max-width: 1200px) {
  .menu__list {
    font-size: 20px;
  }
  .menu__item a {
    padding: 10px 15px;
  }
}

@media screen and (max-width: 900px) {
  .menu {
    display: flex;
    position: absolute;
    right: -280px;
    top: 20%;
    width: 280px;
    background-color: rgb(25, 39, 53);
    visibility: visible;
    padding-top: 50px;
    transition: transform 0.5s, visibility 0.5s;
    z-index: 1;
    border-radius: 5px;
  }
  .menu__list {
    display: block;
    padding-top: 100px;
    padding-bottom: 20px;
  }
  .menu__link {
    display: inherit;
    color: #ffffff;
    padding: 20px 0px;
    font-family: Roboto, sans-serif;
    font-family: Georgia, Times, 'Times New Roman', serif;
    font-weight: 500;
  }
  .menu__item a {
    padding: 25px 35px;
  }
  .active {
    animation: navlink none;
  }
}
@media (max-width: 480px) {
}
