.lang {
  top: 1%;
  left: 2%;
  width: 60px;
  height: 100px;
  position: absolute;
  z-index: 11;
  transition: 1s;
}
@media (max-width: 600px) {
  .lang {
    margin-top: -30px;
  }
}
@media (max-width: 330px) {
  .lang {
    top: 1%;
    left: 2%;
    width: 40px;
    height: 80px;
    position: absolute;
    z-index: 11;
    transition: 1s;
  }
}
