.logo {
  display: block;
  width: 120px;
  height: 120px;
  border-radius: 50%;
}
.logo p {
  text-align: center;
  color: black;
  font-size: 20px;
}
.logoP1 {
  margin-top: 30px;
}
.logo__img {
  transform: scale(1.2);
  border-radius: 50%;
}
@media (max-width: 600px) {
  .logo {
    display: block;
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }
}
@media (max-width: 330px) {
  .logo {
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
}
