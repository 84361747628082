.zayavka {
  padding: 10px 25px;
  text-align: center;
  font-size: 20px;
  width: 150px;
  background-color: black;
  color: white;
  text-transform: uppercase;
  border: none;
  border-radius: 15px;
  animation-name: zayavka;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
  animation-fill-mode: both;
  /* риски */
  /* animation-duration: 2s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none; */
  cursor: pointer;
}
.showForma {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: -1000px;
  backdrop-filter: blur(4px);
  z-index: 100;
}
.zayavka:hover {
  transition: all 0.7s;
  background-color: azure;
  color: black;
  animation: zayavka none;
}
.zayavka:active {
  background-color: rgb(255, 202, 202);
}
@keyframes zayavka {
  from {
    border: 2px solid rgba(173, 216, 230, 0.7);
  }

  to {
    border: 2px solid rgba(173, 216, 230, 1);
  }
}
@media (max-width: 600px) {
  .zayavka {
    padding: 8px 10px;
    text-align: center;
    font-size: 14px;
    width: 100px;
  }
}
@media (max-width: 330px) {
}
