.price {
  padding-top: 300px;
  background-image: url(../../../img/fon4.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  background-attachment: fixed;
  padding-bottom: 35px;
  color: rgb(21, 4, 62);
}

.price-title {
  padding: 15px;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.1),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  opacity: 0.8;
  text-align: center;
}
.price__items {
  padding: 45px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}
.price__item {
  position: relative;
  padding: 30px;
  background-color: white;
  border-radius: 10px;
}
.price__item-title {
  text-transform: uppercase;
  padding: 20px;
  text-align: center;
  font-size: 25px;
  transition: 0.5s;
  background-color: rgb(236, 252, 253);
  border-radius: 15px;
  margin-bottom: 10px;
}
.price__item-title:hover {
  background-color: blue;
  color: white;
  transition: 0.5s;
}

.price__item-img {
  margin: 10px auto;
}
.price__item-list {
  margin-left: 5%;
  font-size: 18px;
  list-style-type: square;
}
.price__item-price {
  padding: 10px;
  text-align: center;
  margin-top: 40px;
}
.price__item-price span {
  position: absolute;
  bottom: 0;
  left: 30%;
  font-size: 20px;
  padding: 25px 55px;
  background-color: rgb(29, 34, 89);
  color: white;
  border-radius: 50%;
  font: italic;
  font-weight: 600;
}
@media (max-width: 1000px) {
  .price__item-price span {
    position: absolute;
    bottom: 0;
    left: 20%;
    font-size: 20px;
    padding: 25px 55px;
    background-color: rgb(29, 34, 89);
    color: white;
    border-radius: 50%;
    font: italic;
    font-weight: 600;
  }
}
@media (max-width: 800px) {
  .price__items {
    padding: 10px;
    /* display: grid;
    grid-template-columns: repeat(1, 1fr); */
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .price__item-title {
    margin: 0 auto;
  }
  .price__item-price span {
    position: static;
    font-size: 20px;
    padding: 25px 55px;
    background-color: rgb(29, 34, 89);
    color: white;
    border-radius: 50%;
    font: italic;
    font-weight: 600;
  }
}
@media (max-width: 600px) {
  .price {
    padding-top: 200px;
  }
  .price__item-price span {
    display: block;
    width: 70%;
    margin: 0 auto;
  }
}
@media (max-width: 450px) {
  .price__item-price span {
    display: block;
    width: 100%;
    margin: 0 auto;
  }
}
@media (max-width: 320px) {
  .price__item-price span {
    display: block;
    width: 100%;
    margin: 0 auto;
    font-size: 16px;
    padding: 15px 10px;
  }
}
