.main-important-blocks {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.main-important-block {
  width: 95%;
  margin: 0 auto;
  border-radius: 10px;
}
.main-important-h2 {
  text-align: center;
  padding: 5px;
  text-transform: uppercase;
  font-family: Verdana, sans-serif;
}
.main-important-h2 span {
  padding: 25px;
  background-color: rgb(251, 255, 255);
  border-radius: 45%;
  opacity: 1;
  display: block;
  width: 45%;
  margin: 0 auto;
}
.main-important-blocks div {
  background-color: white;
  padding: 25px;
  margin-top: 20px;
  text-align: center;
}
.expert {
  display: block;
  margin: 0 auto;
}
.main-important-block h3 {
  text-align: center;
  padding: 15px;
}
.main-important-block p {
  font-size: 16px;
  text-align: center;
}
.main-important-block ul {
  text-align: left;
  padding-left: 5px;
}
.main-important-block span {
  margin-top: 5px;
  display: inline-block;
  padding: 15px;
  background-color: rgb(3, 112, 137);
  color: white;
  border-radius: 20px;
  text-transform: uppercase;
  font-weight: 600;
}
@media (max-width: 900px) {
  .main-important-blocks {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }
}
@media (max-width: 600px) {
  .main-important-h2 {
    padding: 15px;
  }
  .main-important-h2 span {
    padding: 20px;
    background-color: rgb(251, 255, 255);
    border-radius: 45%;
    opacity: 0.9;
    display: block;
    font-size: 20px;
    width: 80%;
  }
}
