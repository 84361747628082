.header {
  color: #ffffff;
  background-color: rgb(0, 0, 0);
  position: fixed;
  top: 0;
  height: 280px;
  width: 100%;
  z-index: 2;
}

.container {
  max-width: 1260px;
  margin: 0 auto;
}
.header__container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
}
.header__container_links {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
}
@media screen and (max-width: 600px) {
  .header {
    color: #ffffff;
    background-color: rgb(0, 0, 0);
    position: fixed;
    top: 0;
    height: 100px;
    width: 100%;
  }
  .header__container_links {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0px;
  }
}
