.main__first-title {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  margin: 25px auto;
  padding: 35px;
  background-color: white;
  text-align: center;
  gap: 10px;
  border-radius: 5px;
}
.hidden {
  display: none;
}
.main__first-title-img {
  width: 25%;
  border-radius: 0 25px 0 25px;
}
.main__first-title-img2 {
  width: 25%;
  border-radius: 25px 0px 25px 0px;
}
.main__first-title-text h1,
.main__first-title-text h2,
.main__first-title-text h3 {
  text-transform: uppercase;
}
.main__first-title-text h2 {
  padding: 20px 0;
}

.main__services {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  margin-bottom: 20px;
}
.main__services-item {
  border-radius: 5px;
  padding: 15px;
  background-color: rgb(255, 255, 255);
  text-align: center;
}
.main__services-item h2 {
  text-transform: uppercase;
  padding-top: 20px;
  padding-bottom: 20px;
  color: rgb(1, 82, 175);
}
.main-services-price {
  font-size: 20px;
  font-style: italic;
  padding-bottom: 20px;
  color: rgb(3, 95, 199);
}
.main__link-details {
  cursor: pointer;
  border-radius: 10px;
  text-align: center;
  background-color: rgb(7, 80, 140);
  width: 200px;
  height: auto;
  color: white;
}
.main__link-details:hover {
  background-color: rgb(3, 112, 137);
}
.main__link-details a {
  font-size: 20px;
  display: inline-block;
  padding: 15px;
}
.main-camera-img {
  scale: 0.8;
  position: relative;
  top: 10px;
  right: 5px;
}
.main-camera-img-rigth {
  scale: 0.8;
  position: relative;
  top: 10px;
  right: -5px;
}
.main-drone-img {
  scale: 0.8;
  position: relative;
  top: 15px;
  left: -10px;
}
.main__services-img2 {
  width: 100%;
  height: 50%;
}
@media (max-width: 1000px) {
  .main__first-title-text h1 {
    font-size: 25px;
  }
}
@media (max-width: 850px) {
  .main__first-title-text h1 {
    font-size: 20px;
  }
  .main__first-title-text h2 {
    font-size: 18px;
  }
}
@media (max-width: 850px) {
  .main__first-title-text h1 {
    font-size: 16px;
  }
  .main__first-title-text h2 {
    font-size: 15px;
  }
  .main__first-title-text h3 {
    font-size: 14px;
  }
}
@media (max-width: 600px) {
  .hidden2 {
    display: none;
  }
  .visible {
    display: block;
    text-transform: uppercase;
    font-size: 25px;
  }
  .main__first-title {
    width: 95%;
  }
  .main__link-details {
    margin-left: 10px;
  }
  .main__services-item {
    border-radius: 5px;
    padding: 15px;
    background-color: rgb(255, 255, 255);
    text-align: center;
    width: 95%;
    margin: 0 auto;
  }
  .main__first-title {
    border-radius: 15px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: 25px auto;
    padding: 20px 10px;
    background-color: white;
    text-align: center;
    gap: 10px;
  }
  .main__first-title-text h1 {
    font-size: 20px;
  }
  .main__first-title-text h2 {
    font-size: 18px;
  }
  .main__first-title-text h3 {
    font-size: 16px;
  }
  .main__first-title-img {
    width: 80%;
    border-radius: 0 25px 0 25px;
  }
  .main__first-title-img2 {
    width: 80%;
    border-radius: 25px 0px 25px 0px;
  }
  .main__services {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
    margin-bottom: 20px;
  }
}
