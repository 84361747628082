.header__list-social-media {
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid white;
  height: 30px;
  padding-left: 15px;
}
.header__list-social-mediaFooter {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 30px;
  padding-left: 15px;
}
.header__social-link-item {
  display: flex;
  align-self: center;
  transform: scale(0.5);
}
.instagram {
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  scale: 1.3;
}
.facebook {
  background-color: white;
  border-radius: 50%;
}
.facebook:hover {
  scale: 1.1;
  transition: 0.3s;
}

.instagram:hover {
  scale: 1.4;
  transition: 0.3s;
}
@media (max-width: 600px) {
  .header__list-social-media {
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid white;
    height: 30px;
    padding-left: 0px;
  }
}
@media (max-width: 330px) {
  .header__list-social-media {
    scale: 0.8;
    padding-left: 10px;
  }
}
