.header__phone {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-phone-item {
  padding: 15px;
  padding-left: 10px;
  margin: 0;
  width: 100%;
  font-size: 20px;
}
.header-phone-item:hover {
  color: rgb(224, 226, 230);
}
.header__phone-img {
  scale: 0.65;
}
.header__phone-only {
  padding: 15px 0 15px 0;
  font-size: 20px;
}
.header__phone-only:hover {
  color: rgb(91, 118, 163);
}

@media (max-width: 330px) {
  .header__phone-link {
    scale: 0.8;
    position: relative;
    right: -25px;
  }
  .hidden {
    display: none;
  }
}
