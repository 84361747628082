.animate__price,
.animate__about {
  font-size: 7.375rem;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 8.8px;
  height: 100px;
  outline: none;
  animation-name: title-tag;
  animation-duration: 3s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-direction: alternate;
  animation-fill-mode: forwards;
  animation-play-state: running;
}
.svg {
  font-family: Georgia, 'Times New Roman', Times, serif;
}
.svg-contact {
  font-family: Georgia, 'Times New Roman', Times, serif;
}
.svg-about {
  font-family: Georgia, 'Times New Roman', Times, serif;
}

@keyframes title-tag {
  0% {
    fill: rgba(255, 255, 255, 0);
    stroke: #1cb5e0;
    stroke-dashoffset: 25%;
    stroke-dasharray: 0 50%;
    stroke-width: 3px;
  }
  70% {
    fill: rgba(255, 255, 255, 0);
    stroke: #1cb5e0;
  }
  80% {
    fill: rgba(255, 255, 255, 0);
    stroke: #1cb5e0;
  }
  100% {
    fill: #f2f2f2;
    stroke: #1cb5e0;
    stroke: white(255, 255, 255, 0);
    stroke-dashoffset: -30%;
    stroke-dasharray: 50% 0;
    stroke-width: 1px;
  }
}
@media (max-width: 1000px) {
  .animate__about {
    height: 110px;
  }
  .svg-about {
    font-size: 3rem;
    text-transform: uppercase;
    letter-spacing: 7.8px;
    width: 680px;
  }
}
@media (max-width: 750px) {
  .animate__about {
    height: 90px;
  }
  .svg-about {
    font-size: 2.3rem;
    text-transform: uppercase;
    letter-spacing: 7.8px;
    height: 80px;
    width: 550px;
  }
}
@media (max-width: 600px) {
  .animate__about {
    height: 80px;
  }
  .animate__price {
    font-weight: 700;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 8.8px;
    height: 70px;
    animation-name: title-tag;
    animation-duration: 3s;
    animation-timing-function: ease;
    animation-iteration-count: 1;
    animation-direction: alternate;
    animation-fill-mode: forwards;
    animation-play-state: running;
  }
  .svg {
    font-size: 3.9rem;
    width: 230px;
    height: 110px;
  }
  .svg-contact {
    font-size: 2.8rem;
    width: 350px;
    height: 110px;
  }
  .svg-about {
    font-size: 2rem;
    text-transform: uppercase;
    letter-spacing: 5.4px;
    height: 100px;
    width: 450px;
  }
}
@media (max-width: 450px) {
  .animate__about {
    height: 70px;
  }
  .svg-about {
    font-size: 1.5rem;
    text-transform: uppercase;
    letter-spacing: 5.4px;
    height: 100px;
    width: 345px;
  }
  .svg-contact {
    font-size: 2.8rem;
    width: 335px;
    height: 110px;
  }
}
@media (max-width: 370px) {
  .animate__about {
    height: 50px;
  }
  .svg-about {
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: 5px;
    height: 100px;
    width: 300px;
    position: relative;
    top: -20px;
  }
  .animate__price {
    font-weight: 700;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 8.8px;
    height: 70px;
    animation-name: title-tag;
    animation-duration: 3s;
    animation-timing-function: ease;
    animation-iteration-count: 1;
    animation-direction: alternate;
    animation-fill-mode: forwards;
    animation-play-state: running;
  }
  .svg {
    font-size: 3.5rem;
    width: 220px;
    height: 110px;
  }
  .svg-contact {
    font-size: 2.3rem;
    width: 300px;
    height: 110px;
  }
}
@media (max-width: 315px) {
  .animate__about {
    height: 50px;
  }
  .svg-about {
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 4px;
    height: 100px;
    width: 250px;
    position: relative;
    top: -20px;
  }
  .svg-contact {
    font-size: 1.8rem;
    width: 250px;
    height: 100px;
  }
}
