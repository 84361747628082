.header__burger-btn {
  display: none;
  position: relative;
  top: 0;
  right: 10px;
  width: 40px;
  height: 40px;
  border: none;
  outline: none;
  cursor: pointer;
  z-index: 2;
  background-color: transparent;
}
@media screen and (max-width: 900px) {
  .header__burger-btn {
    display: block;
  }
  /* бургер меню */
  .header__burger-item {
    position: absolute;
    width: 30px;
    height: 4px;
    background-color: white;
    display: block;
    left: 5px;
    transition: background-color 0.3s, opacity 0.3s, transform 0.5s;
  }

  .header__burger-item:nth-child(1) {
    transform: translateY(-10px);
  }
  .header__burger-item:nth-child(3) {
    transform: translateY(10px);
  }
  /* Хрестик до бургер меню */

  .open .header__burger-item:nth-child(1) {
    transform: translateY(0) rotate(45deg);
  }
  .open .header__burger-item:nth-child(2) {
    opacity: 0;
  }
  .open .header__burger-item:nth-child(3) {
    transform: translateY(0) rotate(-45deg);
  }
  .open.menu {
    transform: translateX(-100%);
    visibility: visible;
  }
  .header__burger-btn:hover .header__burger-item {
    background-color: rgb(215, 210, 210);
  }
}
